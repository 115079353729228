export default [
  { text: 'Altbier', value: 'Altbier' },
  { text: 'Amber Ale', value: 'Amber Ale' },
  { text: 'Barleywine', value: 'Barleywine' },
  { text: 'Berliner Weisse', value: 'Berliner Weisse' },
  { text: 'Bière de Garde', value: 'Bière de Garde' },
  { text: 'Blonde Ale', value: 'Blonde Ale' },
  { text: 'Brown Ale', value: 'Brown Ale' },
  { text: 'Cream Ale', value: 'Cream Ale' },
  { text: 'Gose', value: 'Gose' },
  { text: 'Gueuze', value: 'Gueuze' },
  { text: 'Hefeweizen', value: 'Hefeweizen' },
  { text: 'IPA - American', value: 'IPA - American' },
  { text: 'IPA - English', value: 'IPA - English' },
  { text: 'IPA - Imperial', value: 'IPA - Imperial' },
  { text: 'IPA - Session', value: 'IPA - Session' },
  { text: 'Kölsch', value: 'Kölsch' },
  { text: 'Lambic', value: 'Lambic' },
  { text: 'Pale Ale', value: 'Pale Ale' },
  { text: 'Pilsner', value: 'Pilsner' },
  { text: 'Porter', value: 'Porter' },
  { text: 'Red Ale', value: 'Red Ale' },
  { text: 'Saison', value: 'Saison' },
  { text: 'Scotch Ale', value: 'Scotch Ale' },
  { text: 'Stout - American', value: 'Stout - American' },
  { text: 'Stout - American Imperial', value: 'Stout - American Imperial' },
  { text: 'Stout - Milk/Sweet', value: 'Stout - Milk/Sweet' },
  { text: 'Stout - Oatmeal', value: 'Stout - Oatmeal' },
  { text: 'Stout - Russian Imperial', value: 'Stout - Russian Imperial' },
  { text: 'Schwarzbier', value: 'Schwarzbier' },
  { text: 'Vienna Lager', value: 'Vienna Lager' },
  { text: 'Witbier', value: 'Witbier' },
  { text: 'Weissbier', value: 'Weissbier' }
];

